
.footer {
	position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
	height: 50px;
	width: 100%;
	padding: 1vh 0;
	text-align: center;
    background-color: var(--light-blue) !important;
    z-index: 1;

    @media screen and (max-width: 700px) {
        padding: 3vh 0;
    }

    p {
        margin: 0 10vw;
    }

	.close {
        background: none;
        border: none;
        position: absolute;
        top: 15px;
        right: 10px;
        margin-right: 10px;
        border-radius: 10px;
		font-size: 20px;
		opacity: .8;

		&:hover {
			opacity: 1;
			cursor: pointer;
		}
	}
}