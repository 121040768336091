
.body {
	&#homePage {
		display: flex;
		flex-direction: column;
		gap: 4vw;
		align-items: center;

		.card-box,
		.home-infos {
			display: flex;
			justify-content: center;
			gap: 4vw;

			@media screen and (max-width: 1000px) {
				flex-wrap: wrap;
			}
			@media screen and (max-width: 550px) {
				flex-wrap: nowrap;
				flex-direction: column;
				gap: 20px;
				width: 180px;
				margin: 6vh auto;
				align-items: center;
			}
		}

		.card-box {
			.card-row {
				display: flex;
				gap: 40px;
				padding: 3vh 2vw;
				background-color: #FFF;
				border-radius: 10px;
	
				@media screen and (max-width: 700px) {
					gap: 2vw;
				}
				@media screen and (max-width: 550px) {
					padding: 15px;
					
					&:first-of-type {
						flex-direction: column;
					}
				}
	
				.card {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: center;
					gap: 2vh;
					width: 150px;
					-webkit-transition: all 0.5s ease;
					transition: all 0.5s ease;
					padding: 2vh 1vw;
					border-radius: 10px;
	
					@media screen and (max-width: 700px) {
						width: 25vw;
					}
					@media screen and (max-width: 550px) {
						width: 170px;
					}
	
					img {
						height: 100px;
	
						@media screen and (max-width: 700px) {
							height: 14vw;
						}
						@media screen and (max-width: 550px) {
							height: 90px;
						}
					}
					h2 {
						margin: 0;
						font-size: 18px;
						font-weight: 500;
						text-align: center;
	
						@media screen and (max-width: 700px) {
							font-size: 3vw;
						}
						@media screen and (max-width: 550px) {
							font-size: 18px;
						}
					}
	
					&:hover {
						box-shadow: 0 0 20px #0000002a;
						-webkit-transform: scale(1.1, 1.1);
						transform: scale(1.1, 1.1);
						cursor: pointer;
					}
				}
	
				&:nth-of-type(2){
					.card {
						h2 {
							color: var(--jra-blue);
						}
					}
				}
			}
		}

		.home-infos {
			width: fit-content;

			ul {
				display: flex;
				gap: 4vw;
				padding: 3vh 2vw;
				background-color: #FFF;
				border-radius: 10px;
				
				.list {
					min-width: 250px;

					.header-list {
						display: flex;
						align-items: center;
						gap: 10px;
					}

					.MuiList-root {
						.MuiListItemText-root {
							margin: 7px 12px;
							border-bottom: 1px solid var(--light-grey);
	
							p {
								margin: 0;
							}
	
							&.voir-plus {
								margin: 17px 12px 7px;
								border: none;
								opacity: .7;
	
								&:hover {
									opacity: 1;
								}
							}
						}
					}
				}
			}
		}
	}
}


