
.body {
	
	&#contactPage,
	&#filePage {
		margin: 3vh auto 0;

		@media screen and (max-width: 550px) {
			margin: 0 auto;
		}
		
		.flex {
			margin-bottom: 2vh;

			@media screen and (max-width: 550px) {
				margin-bottom: 8vh;
			}
			@media screen and (max-width: 450px) {
				font-size: 20px;
			}

			h2 {
				margin: 2vh 0;

				@media screen and (max-width: 700px) {
					font-size: 23px;
				}
			}
		}

		// defaut responsive colonnes
		.table.MuiDataGrid-root {
			.css-yrdy0g-MuiDataGrid-columnHeaderRow > div:nth-of-type(-n + 3),
			.css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row > div:nth-of-type(-n + 3) {
				display: flex !important;
			}
		
			.MuiChip-root {
				border: none;
			}
			.MuiDataGrid-cell:focus-within {
				outline: none !important;
			}
		}

		.MuiDataGrid-virtualScroller {
			overflow-y: auto !important;
			max-height: 55vh;

			@media screen and (max-height: 750px) {
				max-height: 50vh;
			}
			@media screen and (max-height: 700px) {
				max-height: 45vh;
			}
		}
	}
	
	&#contactPage {
		.table.MuiDataGrid-root {

			@media screen and (max-width: 1600px) {
				.MuiDataGrid-virtualScrollerContent {
					width: fit-content !important;
				}

				.css-yrdy0g-MuiDataGrid-columnHeaderRow > div:nth-last-of-type(-n + 4),
				.css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row > div:nth-last-of-type(-n + 4) {
					display: none;
				}
			}

			.MuiDataGrid-row {
				cursor: pointer;

				.MuiDataGrid-cellContent {
					cursor: pointer;
				}
			}
		}

		.no-data {
			

			> div {
				display: flex;
				gap: 4vw;
	
				@media screen and (max-width: 700px) {
					flex-direction: column-reverse;
					gap: 0;
				}
				@media screen and (max-width: 550px) {
					width: 50%;
					margin: -68px 0 0 0;
				}
				@media screen and (max-width: 415px) {
					width: 60%;
				}

				p {
					display: flex;
					flex-direction: column;
					margin: 0;
				}
	
				div {
					@media screen and (max-width: 700px) {
						margin: 0 0 -5px 160px;
						transform: rotate(15deg);
					}
					@media screen and (max-width: 550px) {
						transform: none;
					}
	
					.arrow {
						height: 125px;
						margin: 0 0 0 -30px;
						transform: rotate(337deg);
		
						@media screen and (max-width: 850px) {
							margin: 25px 0 0 -110px;
						}
						@media screen and (max-width: 700px) {
							width: fit-content;
							margin: -120px 0 -20px 45px;
							transform: scaleY(-1);
						}
						@media screen and (max-width: 550px) {
							margin: 0 0 -170px 45px;
							transform: rotate(337deg);
						}
						@media screen and (max-width: 500px) {
							transform: rotate(305deg);
						}
						@media screen and (max-width: 415px) {
							margin: 0 0 -200px -45px;
						}
					}
				}
			}
		}
	}

	&#filePage {
		.table.MuiDataGrid-root {
			// @media screen and (max-width: 950px) {
			// 	.css-yrdy0g-MuiDataGrid-columnHeaderRow > div:nth-last-of-type(4),
			// 	.css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row > div:nth-last-of-type(4) {
			// 		display: none;
			// 	}
			// }
			// @media screen and (max-width: 1150px) {
			// 	.css-yrdy0g-MuiDataGrid-columnHeaderRow > div:nth-last-of-type(3),
			// 	.css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row > div:nth-last-of-type(3) {
			// 		display: none;
			// 	}
			// }
			// @media screen and (max-width: 1350px) {
			// 	.css-yrdy0g-MuiDataGrid-columnHeaderRow > div:nth-last-of-type(2),
			// 	.css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row > div:nth-last-of-type(2) {
			// 		display: none;
			// 	}
			// }
			// @media screen and (max-width: 1450px) {
			// 	.MuiDataGrid-virtualScrollerContent {
			// 		width: fit-content !important;
			// 	}
			// 	.css-yrdy0g-MuiDataGrid-columnHeaderRow > div:last-of-type,
			// 	.css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row > div:last-child {
			// 		display: none;
			// 	}
			// }

			.MuiDataGrid-row.Mui-hovered, 
			.MuiDataGrid-row:hover {
				background-color: inherit;
			}
			
			.MuiDataGrid-cellContent {
				cursor: default;
			}
		}
	}
}


