.form {
  padding: 5vh 4vw 3vh !important;
}
@media screen and (max-width: 1350px) {
  .form {
    padding: 2vh 4vw 2vh !important;
  }
}
.form .content {
  display: flex;
  justify-content: space-between;
  gap: 4vw;
}
@media screen and (max-width: 1350px) {
  .form .content {
    flex-direction: column;
    gap: 0;
  }
}
@media screen and (max-width: 700px) {
  .form .content {
    gap: 4vw;
  }
}
.form .content .MuiFormControl-root {
  width: 100%;
  margin: 2vh 0;
}
.form .content > div {
  display: flex;
}
.form .content > div.infos {
  gap: 4vw;
}
@media screen and (max-width: 700px) {
  .form .content > div.infos {
    flex-direction: column;
    align-self: center;
  }
}
.form .content > div.infos .infos-1,
.form .content > div.infos .infos-2 {
  display: flex;
  flex-direction: column;
  width: 300px;
}
.form .content > div.infos .infos-2 .tel {
  display: flex;
  justify-content: space-between;
}
.form .content > div.infos .infos-2 .tel .MuiFormControl-root {
  width: 49%;
}
.form .content > div.infos .infos-2 .codePostal {
  width: 49%;
}
.form .content > div.checks {
  display: flex;
  flex-direction: column;
  width: 300px;
}
@media screen and (max-width: 700px) {
  .form .content > div.checks {
    align-self: center;
  }
}
.form .content > div.checks .labels {
  display: flex;
  gap: 10px;
  justify-content: end;
}
.form .content > div.checks .labels svg {
  padding: 7px;
}
.form .content > div.checks .checkGroup {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 1vw;
  justify-content: space-between;
}
.form .content > div.checks .checkGroup:first-of-type, .form .content > div.checks .checkGroup:nth-of-type(2), .form .content > div.checks .checkGroup:nth-of-type(6) {
  margin-bottom: 10px;
}
.form .content > div.checks .checkGroup .radioGroup {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.form .content > div.checks .checkGroup .radioGroup label {
  margin: 0;
}
.form .content > div.checks .checkGroup .radioGroup label .MuiButtonBase-root {
  padding: 5px 9px;
}
.form .content > div.checks .checkGroup p {
  margin: 0;
}
.form button {
  align-self: center;
  width: -moz-fit-content;
  width: fit-content;
  margin: 6vh 0 0;
  background-color: var(--jra-blue);
}/*# sourceMappingURL=Form.css.map */