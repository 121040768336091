

// HEADER
#header {
	position: relative;
	z-index: 50;
	
	header {
		color: #000 !important;
		background-color: #FFF !important;
		box-shadow: 0 1px 1px 1px #c6c6c6 !important;
	
		.MuiToolbar-root {
	
			.MuiStack-root {
				gap: 3vw;

				> div {
					display: flex;
					align-items: center;
					gap: 2vw;

					// burger
					.menu-button {
						--s: 20px;
						--c: black;
	
						@media screen and (max-width: 700px) {
							--s: 30px;
						}
						
						height: var(--s);
						aspect-ratio: 1;
						border: none;
						padding: 0;
						border-inline: calc(var(--s)/2) solid #0000; 
						box-sizing: content-box;
						--_g1: linear-gradient(var(--c) 20%,#0000 0 80%,var(--c) 0) 
							no-repeat content-box border-box;
						--_g2: radial-gradient(circle closest-side at 50% 12.5%,var(--c) 95%,#0000) 
							repeat-y content-box border-box;
						background: 
							var(--_g2) left  var(--_p,0px) top,
							var(--_g1) left  calc(var(--s)/10 + var(--_p,0px)) top,
							var(--_g2) right var(--_p,0px) top,
							var(--_g1) right calc(var(--s)/10 + var(--_p,0px)) top;
						background-size: 
							20% 80%,
							40% 100%;
						position: relative;
						clip-path: inset(0 25%);
						-webkit-mask: linear-gradient(90deg,#0000,#000 25% 75%,#0000);
						cursor: pointer;
						transition: 
						background-position .3s var(--_s,.3s), 
							clip-path 0s var(--_s,.6s);
						-webkit-appearance:none;
						-moz-appearance:none;
						appearance:none;
	
						&:before,
						&:after {
							content:"";
							position: absolute;
							border-radius: var(--s);
							inset: 40% 0;
							background: var(--c);
							transition: transform .3s calc(.3s - var(--_s,.3s));
						}
						
						&:checked {
							clip-path: inset(0);
							--_p: calc(-1*var(--s));
							--_s: 0s;
						}
						&:checked:before {
							transform: rotate(45deg);
						}
						&:checked:after {
							transform: rotate(-45deg);
						}
						&:focus-visible {
							clip-path: none;
							-webkit-mask: none;
							border: none;
							outline: 2px solid var(--c);
							outline-offset: 5px;
						}
					}
		
					img {
						height: 65px;
						margin: 5px 0;

						@media screen and (max-width: 700px) {
							height: 60px;
							width: 70px;
							object-fit: cover;
							object-position: 0;
						}
					}
				}
	

				h1 {
					margin: 0 0 0 1vw;
					font-size: 25px !important;
					font-weight: 500;
				}
			}
	
			.link {
				text-transform: uppercase;

				.login-btn {
					@media screen and (max-width: 550px) {
						display: none;
					}
				}
				.login-svg {
					display: none;

					@media screen and (max-width: 550px) {
						display: block;
					}
				}
			}
		}
	}
}


// NAV

.MuiDrawer-root.nav {
	z-index: 25 !important;
	
	.MuiPaper-root {
		padding-top: 100px;
	}

	ul.MuiList-root {
		li.MuiListItem-root {
			@media screen and (max-width: 650px) {
				font-size: 18px;
			}

			svg {
				fill: var(--grey);

				@media screen and (max-width: 650px) {
					height: 40px;
					width: 40px;
				}
			}
			
			a {
				width: 100%;
				color: var(--grey);
				text-decoration: none;
			}
		
			&.active {
				svg {
					fill: var(--jra-blue);
				}
				a {
					color: var(--jra-blue);
					font-weight: 700;
				}
			}
		
		}
	}
}
