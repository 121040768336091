
.body {
	&#historyPage {

		.flex {
			margin-bottom: 2vh;
		}

		.scroll {
			max-height: 70vh;
    		overflow-y: auto;

			@media screen and (max-height: 750px) {
				max-height: 60vh;
			}
			@media screen and (max-height: 550px) {
				max-height: 50vh;
			}

			.MuiGrid-root {
				flex-direction: column-reverse;
				justify-content: center;
				gap: 3vh;
				margin: 4vh 0;
				
				.date {
					min-width: 360px;
					max-width: 1000px;
					background-color: inherit;
					border-radius: 10px;
					box-shadow: none;
	
					h3 {
						margin: 0;
						padding-bottom: 5px;
						border-bottom: 1px solid var(--light-grey);
					}
					h3,
					h4, 
					h5 {
						display: flex;
						align-items: center;
					}
	
					h3,
					h4 {
						gap: 2vw;
						opacity: .8;
	
						&:hover {
							opacity: 1;
							cursor: pointer;
						}
	
						&.open {
							opacity: 1;
	
							svg.arrow {
								transition: .5s;
							}
						}
	
						&.closed {
	
							svg.arrow {
								transform: rotate(-90deg);
								transition: .5s;
							}
						}
					}
	
					.MuiCardContent-root {
						display: flex;
						flex-direction: column;
						gap: 1vh;
						padding-top: 2vh;
						border-bottom-right-radius: 10px;
						border-bottom-left-radius: 10px;
	
						&.closed,
						.contact .contact-content.closed {
							display: none;
						}
	
						.contact {
							padding: 3vh 2vw;
							background-color: #fff;
							border-radius: 10px;
	
							h4,
							p.hour {
								margin: .5vh 0;
							}
	
							.histories {
								display: flex;
								flex-direction: column;
								gap: 1vh;
	
								h5 {
									gap: 1vw;
									margin: 2vh 0 0 0;
	
									svg {
										border: 2px solid;
										border-radius: 50%;								
									}
								}
	
								.history-list {
									display: flex;
									flex-direction: column-reverse;
									gap: 1vh;
								}
	
								&.ajout {
									h5 {
										svg {
											height: 28px;
											width: 28px;
											padding: 2px;
											fill: var(--green) !important;
											border-color: var(--green);
										}
									}
	
									.history {
										.value {
											span {
												color: var(--green);
											}
										}
									}
								}
	
								&.modif,
								&.suppression {
									h5 {
										svg {
											height: 23px;
											width: 23px;
											padding: 5px;
										}
									}
	
									.history {
										.value {
											gap: 10px;
		
											span:first-of-type {
												color: var(--light-grey);
											}
										}
									}
								}
	
								&.modif {
									h5 {
										svg {
											fill: var(--orange) !important;
											border-color: var(--orange);
										}
									}
									
								}
	
								&.suppression {
									h5 {
										svg {
											fill: var(--red) !important;
											border-color: var(--red);
										}
									}
	
									.history {
										.value {
											span {
												text-decoration: line-through;
											}
										}
									}
								} 
		
								.history {
									padding: 2vh 2vw;
									border: 1px solid var(--light-grey);
									border-radius: 10px;
		
									p {
										&.hour {
											margin: 0 0 10px 0;
											font-weight: bold;
										}
	
										&.value {
											display: flex;
											gap: 10px;
											margin: 0;
										}
	
										&.editor {
											margin: 10px 0 0 0;
											font-size: 12px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}