#header {
  position: relative;
  z-index: 50;
}
#header header {
  color: #000 !important;
  background-color: #FFF !important;
  box-shadow: 0 1px 1px 1px #c6c6c6 !important;
}
#header header .MuiToolbar-root .MuiStack-root {
  gap: 3vw;
}
#header header .MuiToolbar-root .MuiStack-root > div {
  display: flex;
  align-items: center;
  gap: 2vw;
}
#header header .MuiToolbar-root .MuiStack-root > div .menu-button {
  --s: 20px;
  --c: black;
  height: var(--s);
  aspect-ratio: 1;
  border: none;
  padding: 0;
  border-inline: calc(var(--s) / 2) solid rgba(0, 0, 0, 0);
  box-sizing: content-box;
  --_g1: linear-gradient(var(--c) 20%,#0000 0 80%,var(--c) 0)
  	no-repeat content-box border-box;
  --_g2: radial-gradient(circle closest-side at 50% 12.5%,var(--c) 95%,#0000)
  	repeat-y content-box border-box;
  background: var(--_g2) left var(--_p, 0px) top, var(--_g1) left calc(var(--s) / 10 + var(--_p, 0px)) top, var(--_g2) right var(--_p, 0px) top, var(--_g1) right calc(var(--s) / 10 + var(--_p, 0px)) top;
  background-size: 20% 80%, 40% 100%;
  position: relative;
  -webkit-clip-path: inset(0 25%);
          clip-path: inset(0 25%);
  -webkit-mask: linear-gradient(90deg, rgba(0, 0, 0, 0), #000 25% 75%, rgba(0, 0, 0, 0));
  cursor: pointer;
  transition: background-position 0.3s var(--_s, 0.3s), -webkit-clip-path 0s var(--_s, 0.6s);
  transition: background-position 0.3s var(--_s, 0.3s), clip-path 0s var(--_s, 0.6s);
  transition: background-position 0.3s var(--_s, 0.3s), clip-path 0s var(--_s, 0.6s), -webkit-clip-path 0s var(--_s, 0.6s);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media screen and (max-width: 700px) {
  #header header .MuiToolbar-root .MuiStack-root > div .menu-button {
    --s: 30px;
  }
}
#header header .MuiToolbar-root .MuiStack-root > div .menu-button:before, #header header .MuiToolbar-root .MuiStack-root > div .menu-button:after {
  content: "";
  position: absolute;
  border-radius: var(--s);
  inset: 40% 0;
  background: var(--c);
  transition: transform 0.3s calc(0.3s - var(--_s, 0.3s));
}
#header header .MuiToolbar-root .MuiStack-root > div .menu-button:checked {
  -webkit-clip-path: inset(0);
          clip-path: inset(0);
  --_p: calc(-1*var(--s));
  --_s: 0s;
}
#header header .MuiToolbar-root .MuiStack-root > div .menu-button:checked:before {
  transform: rotate(45deg);
}
#header header .MuiToolbar-root .MuiStack-root > div .menu-button:checked:after {
  transform: rotate(-45deg);
}
#header header .MuiToolbar-root .MuiStack-root > div .menu-button:focus-visible {
  -webkit-clip-path: none;
          clip-path: none;
  -webkit-mask: none;
  border: none;
  outline: 2px solid var(--c);
  outline-offset: 5px;
}
#header header .MuiToolbar-root .MuiStack-root > div img {
  height: 65px;
  margin: 5px 0;
}
@media screen and (max-width: 700px) {
  #header header .MuiToolbar-root .MuiStack-root > div img {
    height: 60px;
    width: 70px;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: 0;
       object-position: 0;
  }
}
#header header .MuiToolbar-root .MuiStack-root h1 {
  margin: 0 0 0 1vw;
  font-size: 25px !important;
  font-weight: 500;
}
#header header .MuiToolbar-root .link {
  text-transform: uppercase;
}
@media screen and (max-width: 550px) {
  #header header .MuiToolbar-root .link .login-btn {
    display: none;
  }
}
#header header .MuiToolbar-root .link .login-svg {
  display: none;
}
@media screen and (max-width: 550px) {
  #header header .MuiToolbar-root .link .login-svg {
    display: block;
  }
}

.MuiDrawer-root.nav {
  z-index: 25 !important;
}
.MuiDrawer-root.nav .MuiPaper-root {
  padding-top: 100px;
}
@media screen and (max-width: 650px) {
  .MuiDrawer-root.nav ul.MuiList-root li.MuiListItem-root {
    font-size: 18px;
  }
}
.MuiDrawer-root.nav ul.MuiList-root li.MuiListItem-root svg {
  fill: var(--grey);
}
@media screen and (max-width: 650px) {
  .MuiDrawer-root.nav ul.MuiList-root li.MuiListItem-root svg {
    height: 40px;
    width: 40px;
  }
}
.MuiDrawer-root.nav ul.MuiList-root li.MuiListItem-root a {
  width: 100%;
  color: var(--grey);
  text-decoration: none;
}
.MuiDrawer-root.nav ul.MuiList-root li.MuiListItem-root.active svg {
  fill: var(--jra-blue);
}
.MuiDrawer-root.nav ul.MuiList-root li.MuiListItem-root.active a {
  color: var(--jra-blue);
  font-weight: 700;
}/*# sourceMappingURL=Header.css.map */