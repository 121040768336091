.body#homePage {
  display: flex;
  flex-direction: column;
  gap: 4vw;
  align-items: center;
}
.body#homePage .card-box,
.body#homePage .home-infos {
  display: flex;
  justify-content: center;
  gap: 4vw;
}
@media screen and (max-width: 1000px) {
  .body#homePage .card-box,
  .body#homePage .home-infos {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 550px) {
  .body#homePage .card-box,
  .body#homePage .home-infos {
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 20px;
    width: 180px;
    margin: 6vh auto;
    align-items: center;
  }
}
.body#homePage .card-box .card-row {
  display: flex;
  gap: 40px;
  padding: 3vh 2vw;
  background-color: #FFF;
  border-radius: 10px;
}
@media screen and (max-width: 700px) {
  .body#homePage .card-box .card-row {
    gap: 2vw;
  }
}
@media screen and (max-width: 550px) {
  .body#homePage .card-box .card-row {
    padding: 15px;
  }
  .body#homePage .card-box .card-row:first-of-type {
    flex-direction: column;
  }
}
.body#homePage .card-box .card-row .card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2vh;
  width: 150px;
  transition: all 0.5s ease;
  padding: 2vh 1vw;
  border-radius: 10px;
}
@media screen and (max-width: 700px) {
  .body#homePage .card-box .card-row .card {
    width: 25vw;
  }
}
@media screen and (max-width: 550px) {
  .body#homePage .card-box .card-row .card {
    width: 170px;
  }
}
.body#homePage .card-box .card-row .card img {
  height: 100px;
}
@media screen and (max-width: 700px) {
  .body#homePage .card-box .card-row .card img {
    height: 14vw;
  }
}
@media screen and (max-width: 550px) {
  .body#homePage .card-box .card-row .card img {
    height: 90px;
  }
}
.body#homePage .card-box .card-row .card h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
@media screen and (max-width: 700px) {
  .body#homePage .card-box .card-row .card h2 {
    font-size: 3vw;
  }
}
@media screen and (max-width: 550px) {
  .body#homePage .card-box .card-row .card h2 {
    font-size: 18px;
  }
}
.body#homePage .card-box .card-row .card:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1647058824);
  transform: scale(1.1, 1.1);
  cursor: pointer;
}
.body#homePage .card-box .card-row:nth-of-type(2) .card h2 {
  color: var(--jra-blue);
}
.body#homePage .home-infos {
  width: -moz-fit-content;
  width: fit-content;
}
.body#homePage .home-infos ul {
  display: flex;
  gap: 4vw;
  padding: 3vh 2vw;
  background-color: #FFF;
  border-radius: 10px;
}
.body#homePage .home-infos ul .list {
  min-width: 250px;
}
.body#homePage .home-infos ul .list .header-list {
  display: flex;
  align-items: center;
  gap: 10px;
}
.body#homePage .home-infos ul .list .MuiList-root .MuiListItemText-root {
  margin: 7px 12px;
  border-bottom: 1px solid var(--light-grey);
}
.body#homePage .home-infos ul .list .MuiList-root .MuiListItemText-root p {
  margin: 0;
}
.body#homePage .home-infos ul .list .MuiList-root .MuiListItemText-root.voir-plus {
  margin: 17px 12px 7px;
  border: none;
  opacity: 0.7;
}
.body#homePage .home-infos ul .list .MuiList-root .MuiListItemText-root.voir-plus:hover {
  opacity: 1;
}/*# sourceMappingURL=Home.css.map */