/* generated global stylesheet */

// https://coolors.co/008ec8-74c61d-d32a2d-f78234-ffc800-8b8b8b-c2c2c2
:root {
	--jra-blue: #008EC8;
	--light-blue: #CCE9F0;
	--green: #74C61D;
	--red: #D32A2D;
	--orange: #F78234;
	--yellow: #FFC800;
	--grey: #8B8B8B;
	--light-grey: #c2c2c2;
}

html,
body,
#root {
	height: 100vh;
}

.App {
  	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
  	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}


/////////////////////////


body {
	background-color: #f9f9f9;

	a {
		color: #000;
		font-weight: 400;
		text-decoration: none;
	}
}

.flex {
	display: flex;
}

.scroll {
	&::-webkit-scrollbar {
		height: 8px;
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		background-color: #fff;
		border-radius: 10px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: var(--light-grey);
		border-radius: 10px;
	}
}

.loading {
	position: absolute;
	top: 50%;
	left: 50%;
	height: 50px !important;
    width: 50px !important;
	color: #FFF !important;
	translate: -50% -50%;
	z-index: 9999;
}

.MuiAlert-root {
	position: absolute;
    top: 85px;
    right: 5px;
	width: fit-content !important;
    z-index: 9999;

	&.css-wnkddu-MuiPaper-root-MuiAlert-root { // success
		background-color: #bfe0c0 !important;
	}
	&.css-1hdyuqk-MuiPaper-root-MuiAlert-root { // error
		background-color: #fdd0d0 !important;
	}
}

// BODY GENERAL

.body {
	min-width: 85%;
	max-width: 90%;
	width: fit-content;
	margin: 5vh auto 0;

	@media screen and (max-width: 850px){
		width: 90%;
		margin: 5vh auto;
	}
}


