.form {
	padding: 5vh 4vw 3vh !important;

	@media screen and (max-width: 1350px) {
		padding: 2vh 4vw 2vh !important;
	}

	.content {
		display: flex;
		justify-content: space-between;
		gap: 4vw;

		@media screen and (max-width: 1350px) {
			flex-direction: column;
			gap: 0;
		}
		@media screen and (max-width: 700px) {
			gap: 4vw;
		}

		.MuiFormControl-root {
			width: 100%;
			margin: 2vh 0;
		}

		> div {
			display: flex;

			&.infos {
				gap: 4vw;

				@media screen and (max-width: 700px) {
					flex-direction: column;
					align-self: center;
				}

				.infos-1,
				.infos-2 {
					display: flex;
					flex-direction: column;
					width: 300px;
				}

				.infos-2 {
					.tel {
						display: flex;
						justify-content: space-between;
	
						.MuiFormControl-root {
							width: 49%;
						}
					}
					.codePostal {
						width: 49%;
					}
				}
			}

			&.checks {
				display: flex;
				flex-direction: column;
				width: 300px;

				@media screen and (max-width: 700px) {
					align-self: center;
				}

				.labels {
					display: flex;
					gap: 10px;
					justify-content: end;

					svg {
						padding: 7px;
					}
				}

				.checkGroup {
					display: flex;
					flex-direction: row-reverse;
					align-items: center;
					gap: 1vw;
					justify-content: space-between;

					&:first-of-type, 
					&:nth-of-type(2), 
					&:nth-of-type(6){ 
						margin-bottom: 10px;
					}

					.radioGroup {
						display: flex;
						flex-direction: row;
						gap: 10px;
						
						label {
							margin: 0;

							.MuiButtonBase-root {
								padding: 5px 9px;
							}
						}
					}
					
					p {
						margin: 0;
					}
				}
			}
		}
	}

	button {
		align-self: center;
		width: fit-content;
		margin: 6vh 0 0;
		background-color: var(--jra-blue);
	}
}