.StyledModal {
	position: fixed;
	z-index: 1300;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #00000080;
}

.modal {
	margin: 0;
	padding-block: 0rem;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
    width: fit-content;
    max-width: 85vw;
	max-height: 100vh;
	background-color: rgb(255, 255, 255);
	border: none;
	border-radius: 10px;
	overflow-y: auto;
	box-shadow: 0 0 20px 2px #00000080;

	@media screen and (max-width: 1200px) {
		max-width: 95vw;
	}
	@media screen and (max-width: 700px) {
		max-width: inherit;
		width: 95vw;
	}

	.close {
		background: none;
		border: none;
		position: absolute;
		top: 15px;
		right: 10px;
		margin-right: 10px;
		border-radius: 10px;

		&:hover {
			cursor: pointer;
		}
	}

	.modalHeader {
		width: fit-content;
		max-width: 80%;
		margin: 2vh auto;
	}
}
