@media screen and (max-width: 550px) {
  #filtersBox {
    justify-content: start;
  }
}
#filtersBox .none {
  display: none;
}
#filtersBox .button-box {
  display: flex;
  align-items: center;
  justify-content: end;
  margin: -7vh 0 2vh;
}
#filtersBox #summaryPhrase {
  display: flex;
  gap: 1vw;
  margin: 0 0 3vh;
}
#filtersBox #summaryPhrase p {
  max-width: 70%;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
  padding: 1vh 1vw;
  font-size: 17px;
  font-weight: 400;
  border: 2px solid var(--jra-blue);
  border-radius: 10px;
}
#filtersBox #summaryPhrase p > span span {
  font-weight: bold;
}

@media screen and (max-width: 700px) {
  .MuiPaper-root {
    width: 70vw;
  }
}
@media screen and (max-width: 550px) {
  .MuiPaper-root {
    width: 95vw;
  }
}
@media screen and (max-width: 700px) {
  .MuiPaper-root .filters_box {
    width: 100%;
  }
}
.MuiPaper-root .filters_box .filters-header {
  display: flex;
  justify-content: end;
  width: 80%;
  margin: 2vh auto;
}
@media screen and (max-width: 700px) {
  .MuiPaper-root .filters_box .filters-header {
    margin: 1vh auto;
  }
}
@media screen and (max-width: 700px) {
  .MuiPaper-root .filters_box .filters-header svg {
    width: 35px;
    height: 35px;
  }
}
@media screen and (max-width: 700px) {
  .MuiPaper-root .filters_box .MuiFormControl-root label,
  .MuiPaper-root .filters_box .MuiFormControl-root .MuiInputBase-root,
  .MuiPaper-root .filters_box .btn-box button {
    font-size: 20px;
  }
}
.MuiPaper-root .filters_box ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  width: 100%;
}
@media screen and (max-width: 700px) {
  .MuiPaper-root .filters_box ul {
    gap: 1vh;
  }
}
.MuiPaper-root .filters_box ul li {
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
}
.MuiPaper-root .filters_box ul li .MuiFormControl-root {
  width: 100%;
}
.MuiPaper-root .filters_box .btn-box {
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  margin: 2vh 0;
}
.MuiPaper-root .filters_box .btn-box button {
  width: 70%;
}/*# sourceMappingURL=Filters.css.map */