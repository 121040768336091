.body#historyPage .flex {
  margin-bottom: 2vh;
}
.body#historyPage .scroll {
  max-height: 70vh;
  overflow-y: auto;
}
@media screen and (max-height: 750px) {
  .body#historyPage .scroll {
    max-height: 60vh;
  }
}
@media screen and (max-height: 550px) {
  .body#historyPage .scroll {
    max-height: 50vh;
  }
}
.body#historyPage .scroll .MuiGrid-root {
  flex-direction: column-reverse;
  justify-content: center;
  gap: 3vh;
  margin: 4vh 0;
}
.body#historyPage .scroll .MuiGrid-root .date {
  min-width: 360px;
  max-width: 1000px;
  background-color: inherit;
  border-radius: 10px;
  box-shadow: none;
}
.body#historyPage .scroll .MuiGrid-root .date h3 {
  margin: 0;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--light-grey);
}
.body#historyPage .scroll .MuiGrid-root .date h3,
.body#historyPage .scroll .MuiGrid-root .date h4,
.body#historyPage .scroll .MuiGrid-root .date h5 {
  display: flex;
  align-items: center;
}
.body#historyPage .scroll .MuiGrid-root .date h3,
.body#historyPage .scroll .MuiGrid-root .date h4 {
  gap: 2vw;
  opacity: 0.8;
}
.body#historyPage .scroll .MuiGrid-root .date h3:hover,
.body#historyPage .scroll .MuiGrid-root .date h4:hover {
  opacity: 1;
  cursor: pointer;
}
.body#historyPage .scroll .MuiGrid-root .date h3.open,
.body#historyPage .scroll .MuiGrid-root .date h4.open {
  opacity: 1;
}
.body#historyPage .scroll .MuiGrid-root .date h3.open svg.arrow,
.body#historyPage .scroll .MuiGrid-root .date h4.open svg.arrow {
  transition: 0.5s;
}
.body#historyPage .scroll .MuiGrid-root .date h3.closed svg.arrow,
.body#historyPage .scroll .MuiGrid-root .date h4.closed svg.arrow {
  transform: rotate(-90deg);
  transition: 0.5s;
}
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  padding-top: 2vh;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root.closed,
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact .contact-content.closed {
  display: none;
}
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact {
  padding: 3vh 2vw;
  background-color: #fff;
  border-radius: 10px;
}
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact h4,
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact p.hour {
  margin: 0.5vh 0;
}
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact .histories {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact .histories h5 {
  gap: 1vw;
  margin: 2vh 0 0 0;
}
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact .histories h5 svg {
  border: 2px solid;
  border-radius: 50%;
}
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact .histories .history-list {
  display: flex;
  flex-direction: column-reverse;
  gap: 1vh;
}
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact .histories.ajout h5 svg {
  height: 28px;
  width: 28px;
  padding: 2px;
  fill: var(--green) !important;
  border-color: var(--green);
}
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact .histories.ajout .history .value span {
  color: var(--green);
}
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact .histories.modif h5 svg, .body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact .histories.suppression h5 svg {
  height: 23px;
  width: 23px;
  padding: 5px;
}
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact .histories.modif .history .value, .body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact .histories.suppression .history .value {
  gap: 10px;
}
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact .histories.modif .history .value span:first-of-type, .body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact .histories.suppression .history .value span:first-of-type {
  color: var(--light-grey);
}
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact .histories.modif h5 svg {
  fill: var(--orange) !important;
  border-color: var(--orange);
}
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact .histories.suppression h5 svg {
  fill: var(--red) !important;
  border-color: var(--red);
}
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact .histories.suppression .history .value span {
  text-decoration: line-through;
}
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact .histories .history {
  padding: 2vh 2vw;
  border: 1px solid var(--light-grey);
  border-radius: 10px;
}
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact .histories .history p.hour {
  margin: 0 0 10px 0;
  font-weight: bold;
}
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact .histories .history p.value {
  display: flex;
  gap: 10px;
  margin: 0;
}
.body#historyPage .scroll .MuiGrid-root .date .MuiCardContent-root .contact .histories .history p.editor {
  margin: 10px 0 0 0;
  font-size: 12px;
}/*# sourceMappingURL=History.css.map */