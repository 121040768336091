.body#contactPage, .body#filePage {
  margin: 3vh auto 0;
}
@media screen and (max-width: 550px) {
  .body#contactPage, .body#filePage {
    margin: 0 auto;
  }
}
.body#contactPage .flex, .body#filePage .flex {
  margin-bottom: 2vh;
}
@media screen and (max-width: 550px) {
  .body#contactPage .flex, .body#filePage .flex {
    margin-bottom: 8vh;
  }
}
@media screen and (max-width: 450px) {
  .body#contactPage .flex, .body#filePage .flex {
    font-size: 20px;
  }
}
.body#contactPage .flex h2, .body#filePage .flex h2 {
  margin: 2vh 0;
}
@media screen and (max-width: 700px) {
  .body#contactPage .flex h2, .body#filePage .flex h2 {
    font-size: 23px;
  }
}
.body#contactPage .table.MuiDataGrid-root .css-yrdy0g-MuiDataGrid-columnHeaderRow > div:nth-of-type(-n + 3),
.body#contactPage .table.MuiDataGrid-root .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row > div:nth-of-type(-n + 3), .body#filePage .table.MuiDataGrid-root .css-yrdy0g-MuiDataGrid-columnHeaderRow > div:nth-of-type(-n + 3),
.body#filePage .table.MuiDataGrid-root .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row > div:nth-of-type(-n + 3) {
  display: flex !important;
}
.body#contactPage .table.MuiDataGrid-root .MuiChip-root, .body#filePage .table.MuiDataGrid-root .MuiChip-root {
  border: none;
}
.body#contactPage .table.MuiDataGrid-root .MuiDataGrid-cell:focus-within, .body#filePage .table.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}
.body#contactPage .MuiDataGrid-virtualScroller, .body#filePage .MuiDataGrid-virtualScroller {
  overflow-y: auto !important;
  max-height: 55vh;
}
@media screen and (max-height: 750px) {
  .body#contactPage .MuiDataGrid-virtualScroller, .body#filePage .MuiDataGrid-virtualScroller {
    max-height: 50vh;
  }
}
@media screen and (max-height: 700px) {
  .body#contactPage .MuiDataGrid-virtualScroller, .body#filePage .MuiDataGrid-virtualScroller {
    max-height: 45vh;
  }
}
@media screen and (max-width: 1600px) {
  .body#contactPage .table.MuiDataGrid-root .MuiDataGrid-virtualScrollerContent {
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .body#contactPage .table.MuiDataGrid-root .css-yrdy0g-MuiDataGrid-columnHeaderRow > div:nth-last-of-type(-n + 4),
  .body#contactPage .table.MuiDataGrid-root .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row > div:nth-last-of-type(-n + 4) {
    display: none;
  }
}
.body#contactPage .table.MuiDataGrid-root .MuiDataGrid-row {
  cursor: pointer;
}
.body#contactPage .table.MuiDataGrid-root .MuiDataGrid-row .MuiDataGrid-cellContent {
  cursor: pointer;
}
.body#contactPage .no-data > div {
  display: flex;
  gap: 4vw;
}
@media screen and (max-width: 700px) {
  .body#contactPage .no-data > div {
    flex-direction: column-reverse;
    gap: 0;
  }
}
@media screen and (max-width: 550px) {
  .body#contactPage .no-data > div {
    width: 50%;
    margin: -68px 0 0 0;
  }
}
@media screen and (max-width: 415px) {
  .body#contactPage .no-data > div {
    width: 60%;
  }
}
.body#contactPage .no-data > div p {
  display: flex;
  flex-direction: column;
  margin: 0;
}
@media screen and (max-width: 700px) {
  .body#contactPage .no-data > div div {
    margin: 0 0 -5px 160px;
    transform: rotate(15deg);
  }
}
@media screen and (max-width: 550px) {
  .body#contactPage .no-data > div div {
    transform: none;
  }
}
.body#contactPage .no-data > div div .arrow {
  height: 125px;
  margin: 0 0 0 -30px;
  transform: rotate(337deg);
}
@media screen and (max-width: 850px) {
  .body#contactPage .no-data > div div .arrow {
    margin: 25px 0 0 -110px;
  }
}
@media screen and (max-width: 700px) {
  .body#contactPage .no-data > div div .arrow {
    width: -moz-fit-content;
    width: fit-content;
    margin: -120px 0 -20px 45px;
    transform: scaleY(-1);
  }
}
@media screen and (max-width: 550px) {
  .body#contactPage .no-data > div div .arrow {
    margin: 0 0 -170px 45px;
    transform: rotate(337deg);
  }
}
@media screen and (max-width: 500px) {
  .body#contactPage .no-data > div div .arrow {
    transform: rotate(305deg);
  }
}
@media screen and (max-width: 415px) {
  .body#contactPage .no-data > div div .arrow {
    margin: 0 0 -200px -45px;
  }
}
.body#filePage .table.MuiDataGrid-root .MuiDataGrid-row.Mui-hovered,
.body#filePage .table.MuiDataGrid-root .MuiDataGrid-row:hover {
  background-color: inherit;
}
.body#filePage .table.MuiDataGrid-root .MuiDataGrid-cellContent {
  cursor: default;
}/*# sourceMappingURL=Datatable.css.map */