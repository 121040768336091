
#filtersBox {
	@media screen and (max-width: 550px) {
		justify-content: start;
	}

	.none {
		display: none;
	}

	.button-box {
		display: flex;
		align-items: center;
		justify-content: end;
		margin: -7vh 0 2vh;
	}

	#summaryPhrase {
		display: flex;
		gap: 1vw;
		margin: 0 0 3vh;

		p {
			max-width: 70%;
			width: -moz-fit-content;
			width: fit-content;
			margin: 0;
			padding: 1vh 1vw;
			font-size: 17px;
			font-weight: 400;
			border: 2px solid var(--jra-blue);
			border-radius: 10px;

			> span {
				span {
					font-weight: bold;
				}
			}
		}
	} 
}

.MuiPaper-root {
	@media screen and (max-width: 700px) {
		width: 70vw;
	}
	@media screen and (max-width: 550px) {
		width: 95vw;
	}

	.filters_box {
		@media screen and (max-width: 700px) {
			width: 100%;
		}

		.filters-header {
			display: flex;
			justify-content: end;
			width: 80%;
			margin: 2vh auto;

			@media screen and (max-width: 700px) {
				margin: 1vh auto;
			}

			svg {
				@media screen and (max-width: 700px) {
					width: 35px;
					height: 35px;
				}
			}
		}

		@media screen and (max-width: 700px) {
			.MuiFormControl-root label,
			.MuiFormControl-root .MuiInputBase-root,
			.btn-box button {
				font-size: 20px;
			}
		}
	
		ul {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 2vh;
			width: 100%;

			@media screen and (max-width: 700px) {
				gap: 1vh;
			}
	
			li {
				flex-direction: column;
				align-items: flex-start;
				width: 80%;
	
				.MuiFormControl-root {
					width: 100%;
				}
			}
		}
		
		.btn-box {
			flex-direction: column;
			align-items: center;
			gap: 1vh;
			margin: 2vh 0;
	
			button {
				width: 70%;
			}
		}
	}
}